import React from 'react'
import snapshot from "../assets/snapshot.json"
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";

const Homepage = () => {
    const winsPerTeam = snapshot.wins_per_team as Record<string, number>
    const scheduleResults = snapshot.schedule_results
    const upcoming = snapshot.upcoming_schedule
    return (
        <Box>
            <Box>
                <Typography variant="h4">Standings</Typography>
                 <TableContainer component={Paper} sx={{mt: 1, mb: 2}}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell>Team</TableCell>
                            <TableCell>Wins</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(winsPerTeam).map((teamName: string, i: number) => (
                                <TableRow
                                  key={`standings-${i}`}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                 <TableCell component="th" scope="row">
                                    {i + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {teamName}
                                  </TableCell>
                                    <TableCell component="th" scope="row">
                                    {winsPerTeam[teamName]}
                                  </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                 </TableContainer>

                <Typography variant="h4">Results</Typography>
                {scheduleResults.map((weekResult, i) => (
                    <Box>
                        <Typography variant="h5">Week {i + 1}</Typography>
                        <TableContainer component={Paper} sx={{my: 1}}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Home team</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Away team</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                {weekResult.filter(m => m.regular).map((matchup, m: number) => (
                                    <TableRow
                                      key={`matchup-${i}-${m}`}
                                      sx={{ borderBottom: m === 4 ? 2 : undefined }}
                                    >
                                     <TableCell component="th" scope="row" sx={{backgroundColor: matchup.home.won ? "#e6ffe6" : undefined}}>
                                         {matchup.home.team}
                                      </TableCell>
                                      <TableCell component="th" scope="row" sx={{backgroundColor: matchup.home.won ? "#e6ffe6" : undefined}}>
                                        {matchup.home.score}
                                      </TableCell>
                                        <TableCell component="th" scope="row" sx={{backgroundColor: matchup.away.won ? "#e6ffe6" : undefined}}>
                                        {matchup.away.team}
                                      </TableCell>
                                        <TableCell component="th" scope="row" sx={{backgroundColor: matchup.away.won ? "#e6ffe6" : undefined}}>
                                        {matchup.away.score}
                                      </TableCell>
                                    </TableRow>
                                ))}
                                {weekResult.filter(m => !m.regular).map((matchup, m: number) => (
                                    <TableRow
                                      key={`matchup-${i}-${m}`}
                                    >
                                     <TableCell component="th" scope="row" sx={{backgroundColor: matchup.home.won ? "#e6ffe6" : undefined}}>
                                         {matchup.home.team}
                                      </TableCell>
                                      <TableCell component="th" scope="row" sx={{backgroundColor: matchup.home.won ? "#e6ffe6" : undefined}}>
                                        {matchup.home.score}
                                      </TableCell>
                                        <TableCell component="th" scope="row" sx={{backgroundColor: matchup.away.won ? "#e6ffe6" : undefined}}>
                                        {matchup.away.team}
                                      </TableCell>
                                    <   TableCell component="th" scope="row" sx={{backgroundColor: matchup.away.won ? "#e6ffe6" : undefined}}>
                                        {matchup.away.score}
                                      </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                     </TableContainer>
                    </Box>
                ))}

            </Box>
        </Box>
    )
}

export default Homepage